.full-height-container {
  min-height: 100vh;
  padding-bottom: 25px;
}

.res_received {
  min-width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: blue;
  font-size: larger;
  font-style: italic;
}
.message_received {
  font-size: larger;
  color: rgb(15, 15, 78);
  font-weight: bold;
  font-family: sans-serif;
}

.userMessage,
.response {
  flex: 0 0 100%;
  max-width: 100%;
}

.userMessage {
  background-color: #e0e0e0 !important;
  border-radius: 15px 15px 15px 0;
}

.response {
  background-color: #4e647a !important;
  color: #ffffff !important;
  border-radius: 15px 15px 0 15px;
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 15px;
}

.message-content {
  flex: 1;
  /* overflow-y: auto; */
}

.warning {
  color: #ff4a4a !important;
  font-size: larger;
}

.message-OK {
  color: #412faa !important;
  font-size: larger;
}

.messages_Box {
  height: 100dvb;
}

.formCol {
  margin: 40px 0;
}

.InputText {
  border: 0;
  border-bottom: solid 1px;
  width: 80%;
}

.InputText:focus {
  box-shadow: none;
}
