.padding-buttom-100px {
  padding-bottom: 100px;
}

.height-100 {
  height: 100%;
}

.max-width-100 {
  max-width: 100%;
}

.min-heigth-100 {
  min-height: 100%;
}

.max-heigth-50 {
  max-height: 50%;
}

.max-heigth-100 {
  max-height: 100%;
}

p {
  font-size: medium !important;
  text-align: justify;
}

.bb {
  border: 2px solid blue;
}
.br {
  border: 2px solid red;
}
.scrollable_Box {
  height: 100vh;
  padding: 20px;
  overflow-y: auto;
}

.bg-white {
  background-color: white;
}

.display-none {
  display: none;
}

.visibility-hidden {
  visibility: hidden;
}

.Posation-relative {
  position: relative !important;
}

h5,
p {
  padding: 10px;
}
