

.header-wrapper {
  max-height: 10%;
  background: #2c3e50;
  color: white;
  height: 5%;
  z-index: 101;
  transition: transform 3s ease;
}

.header-image {
  max-height: 100px;
  margin-left: 30px;
  transition: transform 3s ease;
}

.header-wrapper .row {
  width: 100%;
}

.header-row {
  min-width: 100%;
}

.animate-name {
  font-size: 4rem;
  transition: transform 2s ease;
}

.animate-title {
  margin-left: 40px;
  transition: transform 2s ease;
}
