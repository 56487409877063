.footer-wrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  color: white;
  align-items: center;
  width: 100%;
  height: 2%;
  background: #11476b;
}

.footerWrapper {
  background-color: #2c3e50;
  color: #ffffff;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #dee2e6;
}

footer {
  position: sticky;
  bottom: 0;
  min-width: 100%;
}
.icon {
  font-size: 1.5rem;
  color: #ffffff;
  margin: 0 10px;
  cursor: pointer;
}

.link {
  text-decoration: none;
  font-family: system-ui;
  font-size: 1.2rem;
  color: #ecf0f1;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
  transition: color 0.2s;
}

.link,
.icon {
  transition: color 0.3s ease;
}

.icon:hover,
.link:hover {
  color: #ff7f50;
}

.divider {
  margin: 0 30px 0 30px;
  border-right: 1px solid #ecf0f1;
  height: 2rem;
}
.icon > span,
.icon svg {
  vertical-align: middle;
}
