@keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .column-overlay-page {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black !important;
    color: white;
    font-size: 4rem;
    opacity: 1;
    transition: opacity 4s ease;
  }
  
  @media (max-width: 600px) {
    .column-overlay-page {
      flex-direction: column;
    }
  }
  
  .column-overlay-page.exit {
    color: rgb(131, 6, 6);
    animation: fadeOut 3s forwards;
    pointer-events: none;
  }