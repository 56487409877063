.nav-link:active {
    background-color: #2d5175;
  }
  
  .nav-link.active {
    color: rgb(4, 6, 110);
    font-weight: bold;
  }
  
  .signBtn {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .signBtn > img {
    display: block;
    margin: 0;
    width: 150px;
    height: 30px;
  }