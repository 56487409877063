.hamburger-menu {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 40px;
  width: 40px;
}

.menu-bar {
  height: 3px;
  width: 100%;
  background-color: navy;
  border-radius: 2px;
  transition: all 0.3s ease;
}

.search-container .rounded-search-box {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.search-container .rounded-search-box input {
  border-radius: 20px !important;
}

.search-container .rounded-search-box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.search-container .icon {
  border-radius: 20px !important;
}
.searchCont {
  width: 100%;
}

@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.sideBar_container {
  position: absolute;
  left: 0;
  width: 20%;
  border-right: 2px solid rgb(211, 211, 214);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 51;
  height: 87%;
  padding: 10px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  overflow-x: hidden;
  background-color: hsl(240, 46%, 25%, 0.6);
  backdrop-filter: blur(10px);
  border: 2px solid navy;
  color: rgb(241, 241, 245);
}

.hidden {
  animation-name: slideOutToLeft;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
}

.visible {
  animation-name: slideInFromLeft;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
}

.main-content {
  transition: width 1s;
}


.email-sidebar_folder{
  padding: 7px;
  cursor: pointer;
  margin: 0 10px 0 10px;
}

.sidebarBox{
  margin: 10px 0 10px 0;
}

.horizontalLine{
  display: block;
  border-top: 2px solid rgb(163, 159, 159);
}

div .date{
  margin: 5px 5px;
}

div .date > span{
  margin: 2px 2px;
}

.email_modal_btn{
  width: 100%;
  margin: 2px 2px;
}

.sentFrom {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: rgb(37, 105, 79);
}

.mail_cell {
  font-size: 1.12rem;
}
.email_row{
  border-bottom: 1px solid rgb(202, 197, 197);
}

.email_row:hover, .email_row:hover .mail_cell {
  background-color: rgb(189, 189, 183);
}
.emails_wrapper{
  border: 2px solid blue;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  
}

.email_sender, .email_subject{
  font-weight: bold;
}
.check{
  margin-left: 5px;
}

.star-checkbox {
    display: inline-block;
    font-size: 20px;
    color: #aaa;
    margin: 0 5px 0 5px;
}

.star-checkbox-input {
    display: none;
}

.star-checkbox-label {
    cursor: pointer;
}

.star-checkbox-label-gold {
  cursor: pointer;
  color: gold;
}

.folder-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalcount{
  font-size: 1.1rem;
  color: rgb(16, 12, 12);
  margin-left: 5px;
}

.unreadcount {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: white;
  border: #7a062d solid 1px;
  width: 1.2rem;
  height: 1.2rem;
  background-color: #7a062d;
  border-radius: 5px;
}


.spacer {
  flex-grow: 1;
}

.detailEmail{
  border: 2px solid #0b1297;
  padding: 15px;
  margin: 10px;
  border-radius: 10px;
}


.emailNavbar{
  background-color: #e6e4e9;
  align-items: center;
  padding: 10px;
}

@media (max-width: 1400px) {
  .font-xl {
    font-size: 1.1rem;
  }
}

@media (max-width: 1200px) {
  .font-lg {
    font-size: 1rem;
  }
}

@media (max-width: 992px) {
  .font-md {
    font-size: 1rem;
  }
  .sideBar_container{
    width: 30%;
  }
  .modal-lg {
    max-width: 90% !important;
  }
}

@media (max-width: 768px) {
  .font-sm {
    font-size: 0.6rem;
  }
  .sideBar_container{
    width: 40%;
    height: 76%;
  }
}

@media (max-width: 677px) {

  .sideBar_container{
    width: 60%;
    height: 71%;
  }
}



.txt_gold{
  color: gold;
}

.attachment_btn{
  border: 1px solid lightgray;
  padding: 5px;
  margin-bottom: 10px;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: #f8f8f8;
}

.attachment_btn:hover {
  background-color: #236987;
  color: white;
}

.attachment_btn:active {
  transform: translateY(3px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.slider_wrapper{
  display: flex;
}

.Preview_slider, .Download_slider {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  position: absolute;
  width: 50%;
  height: 100%;
  padding: 2px 0;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0s linear 0.4s; /* Updated */
  color: rgb(55, 29, 29);
  font-size: 0.7rem;
  font-weight: bolder;
}

.Preview_slider {
  left: -50%;
  background-color: #99bece;
  border-radius: 10px 0 0 10px;
}

.Download_slider {
  right: -50%;
  background-color: #85cb9d;
  border-radius: 0 10px 10px 0;
}

.file-upload:hover .Preview_slider, .file-upload:hover .Download_slider {
  opacity: 1;
  visibility: visible;
  transform: translateX(100%);
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.file-upload:hover .Download_slider {
  transform: translateX(-100%);
}



.file-upload{
  
  border: 2px solid blue;
  border-radius: 10px;
  padding: 0 5px;
  margin: 0 5px 5px 5px ;
  position: relative;
  transition: transform 0.1s ease;
  display: inline-block;
  padding:0 20px 0 20px;
  
}

.file-upload:hover{
  transform: scale(1.10);
  box-shadow: 0px 5px 5px -5px gold, 5px 0px 5px -5px gold;

}


.removeX{
  display: none;
}

.file-upload:hover .removeX{
  display: flex;
  width: 20px;
  height: 20px;
  background-color: white;
  border: solid 3px red;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -10px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 12px;
}




.removeX:hover{
  cursor: pointer;
}


.attachement_size{
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid green ;
  background-color: green;
  color: white;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  box-sizing: border-box;
  padding: 0 5px;
  border-radius: 0 0 10px 10px;
}
