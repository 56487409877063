/* ====== Start Flipper ===== */
.flip-container {
  perspective: 1000px;
}

.flipper {
  transition: 1s ease-in-out;
  transform-style: preserve-3d;
  position: relative;
}

.front,
.back {
  backface-visibility: hidden;
  position: relative;
  top: 0;
  left: 0;
  /* width: 100%;
    height: 100%; */
}

.front {
  z-index: 2;
  transform: rotateX(0deg);
}

.back {
  transform: rotateX(180deg);
}

.flip-container.flipped .flipper {
  transform: rotateX(180deg);
}
/* ======= End flipper ====== */
