@media (max-width: 767.98px) {
  .userMessage,
  .response {
    flex: 0 0 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 990px) {
  .image {
    max-height: 150px;
  }
  .content-h-s {
    padding: 25px;
  }
  .full-height-container {
    max-height: 100%;
    /* overflow-y: auto; */
  }
}
@media screen and (max-width: 990px) {
  .image {
    max-height: 150px;
  }
  .content-h-s {
    padding: 25px;
  }
  .full-height-container {
    max-height: 100%;
    /* overflow-y: auto; */
  }
}
@media screen and (max-width: 341px) {
  .signBtn {
    width: 150px;
    height: 30px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .signBtn img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (min-width: 768px) {
    .signBtn {
      display: inline-block;
      /* float: right; */
      margin-right: 10px;
    }

    .navbar-collapse {
      padding-right: 40px;
    }
  }
}

@media (max-width: 767px) {
  .navbar-nav .nav-link:last-child {
    margin-bottom: 40px;
  }
}
