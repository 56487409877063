.App {
  min-height: 100vh;
}

.moreInfoBtn {
  height: 25px;
  text-align: center;
}

.sticky-top {
  box-shadow: 0 4px 4px -2px gray;
}

.loaderC {
  position: relative;
  max-height: 50dvb;
  margin: auto;
  left: 50%;
}

.stickyButtom {
  position: sticky;
  bottom: 0;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  pointer-events: none;
  z-index: 0;
  pointer-events: none;
}

.grecaptcha-badge {
  position: fixed;
  visibility: visible;
  bottom: 115px !important;
  right: 14px;
  background-color: transparent;
}
